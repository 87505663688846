<template>
  <transition :appear="true" name="slide">
    <div class="answer-home" >
      <div class="info_note">
        <div>得票数最多的作品将获得“最佳人气奖”并参加西部</div>
        <div>12省（市、自治区）图书馆人气作品网络投票选拔，</div>
        <div>在此项选拔中进入前两名可参与2024年10月在四川省举办的</div>
        <div>集中展演活动。</div>
      </div>
      <div class="guize">
        <div class="gui-li">投票规则：</div>
        <div class="gui-li">1.每个微信号每天最多能投出5票，可累计投给同一个作品，也可分散投给不同作品。</div>
        <div class="gui-li">2.投票时间为8月20日 17:00 - 8月25日 17:00。</div>
        <div class="gui-li">3.本次评选禁止刷票，主办方有权剔除非正常数据，严重者取消评选资格。</div>
        <div class="gui-li">4.本次活动最终解释权归内蒙古自治区图书馆学会所有。</div>
      </div>
      <div class="userlist">
        <div class="user" v-for="(item,index) in list" :key="index">
          <img :src="item.img_url" class="videopic" @click="play(item)">
          <div class="playbtn" @click="play(item)"></div>
          <div class="info">
            <div class="rank">{{String(index+1).padStart(2,'0')}}</div>
            <div class="user-name">{{item.toupiao_name}}</div>
            <div class="bott">
              <span class="toupiaonum">已投票：{{item.toupiao_num}}</span>
              <div class="btn" @click="totoupiao(item)" v-if="item.hastou && item.hastou.length<1">去投票</div>
              <div class="btn" v-else @click="totoupiao(item)">已投 {{item.hastou.length}} 票</div>
            </div>
          </div>
        </div>
      </div>
      <van-popup v-model="show" style="width:100%;" @close="close">
        <!-- <VideoPlayer v-if="currentitem.m3u84" :show="show" :src="currentitem.m3u8" class="videos"></VideoPlayer> -->
        <video ref="myvideo" :poster="currentitem.img_url" preload="auto" autoplay class="videos" controls :src="currentitem.video_url"></video>
        <!-- <video-player
          class="video-player vjs-custom-skin videos"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
        ></video-player> -->
      </van-popup>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { getCUserInfoNew } from '@/api/Cuser'
import { getVideolist, toupiao } from '@/api/Toupiao'
// import VideoPlayer from './VideoPlayer.vue'

export default {
  name: 'ToupiaoIndex',
  components: {
  },
  computed: {
    ...mapGetters(['userGuid', 'agencyGuid'])
  },
  data () {
    return {
      activity: {},
      anho_st: '',
      btn_st: '',
      btn_st1: '',
      editinfo: false,
      activity_guid: '',
      userinfo: {},
      hasjoin: false,
      show: false,
      currentitem: {},
      loading: false,
      list: [],
      ins: null,
      timer: null,
      infodata: { info_note: '得票数最多的作品将获得“最佳人气奖”并参加西部 12省（市、区）图书馆人气作品网络投票选拔， 在此项选拔中进入前两名可参与20' }
    }
  },
  created () {
    let query = this.$route.query
    this.activity_guid = query.acguid ? query.acguid : '43a9fdf4-c081-4557-9875-e750126d376b'
    this._getVideolist()
    this.ins = setTimeout(() => {
      this._getVideolist()
    }, 100000)
  },
  beforeDestroy () {
    clearTimeout(this.ins)
    clearTimeout(this.timer)
  },
  methods: {
    close () {
      if (this.$refs.myvideo) {
        this.$refs.myvideo.pause()
      }
      this.currentitem = {}
      this.show = false
    },
    play (item) {
      this.currentitem = item
      this.show = true
      if (this.$refs.myvideo) {
        this.$refs.myvideo.play()
      }
    },
    closeguize () {
      this.show_guize = false
    },
    _getVideolist () {
      getVideolist({ c_user_guid: this.userGuid, page: 1, limit: 20 }).then(res => {
        if (res.code === 200) {
          this.list = res.data.list
        }
      })
    },
    _getCUserInfoNew (type = 0) {
      getCUserInfoNew({ c_user_guid: this.userGuid }).then(res => {
        if (res.code === 200) {
          this.userinfo = res.data
          if (!res.data.c_user_phone) {
            this.editinfo = true
          } else {
            if (type === 0) {
              this._cUserActivity()
            }
            this.editinfo = false
          }
        }
      })
    },
    totoupiao (item) {
      this.timer = setTimeout(() => {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中...'
        })
        toupiao({ c_user_guid: this.userGuid, toupiao_id: item.toupiao_id, type: 1 }).then(res => {
          if (res.code === 200) {
            this.$toast.clear()
            // this._getVideolist()
            item.toupiao_num = item.toupiao_num + 1
            item.hastou.push({ toupiao_id: item.toupiao_id })
            return this.$toast.success('投票成功')
          } else {
            return this.$toast.fail(res.msg)
          }
        })
      }, 100)
    },
    tocansole (item) {
      toupiao({ c_user_guid: this.userGuid, toupiao_id: item.toupiao_id, type: 2 }).then(res => {
        if (res.code === 200) {
          // this._getVideolist()
          item.toupiao_num = item.toupiao_num + 1
          item.hastou.push({ toupiao_id: item.toupiao_id })
          return this.$toast.success('投票成功')
        } else {
          return this.$toast.fail(res.msg)
        }
      })
    },
    back () {
      let index = this.$route.path.indexOf('/vieanswer')
      let path = this.$route.path.substring(0, index)
      this.$router.push(path)
    },
    ...mapMutations({
      SET_ACTIVITY: 'SET_ACTIVITY'
    })
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
* {
  touch-action: auto !important;
}
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.answer-home
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0
  height:5854px;
  background RGBA(83, 0, 3, 1)
  background-image:url('~@/assets/img/toupiao/bg.png')
  background-size 100% 100%
  background-repeat no-repeat
  touch-action: auto !important;
  overflow-y:auto;

  .info_note{
    width:calc(100% - 50px);
    height: fit-content;
    margin-left:25px;
    margin-top:253px;
    font-weight: 500;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 42px;
    text-align:center;
  }
  .guize{
    margin-top:318px;
    box-sizing: border-box;
    width: calc(100% - 28px);
    margin-left:14px;
    height: fit-content;
    background: #6D0001;
    border-radius: 14px;
    opacity: 0.8;
    padding:20px 26px;
    font-weight: 500;
    font-size: 25px;
    color: #F8D6A8;
    line-height: 56px;
  }
  .userlist{
    width: calc(100% - 28px);
    height:4500px;
    margin-left:14px;
    margin-top:14px;
    background-image:url('~@/assets/img/toupiao/user-bg.png')
    background-size 100% 100%
    background-repeat no-repeat
    padding-top:144px;
    .user{
      width: calc(100% - 40px);
      margin-left:20px;
      height: 206px;
      background: #FFFFFF;
      border-radius: 25px;
      position:relative;
      margin-bottom:45px;
      .videopic{
        width: 252px;
        height: 217px;
        background: #832222;
        border-radius: 25px 25px 56px 25px;
        position:absolute;
        bottom:0;
      }
      .info{
        width:calc(100% - 252px);
        padding:25px 25px 8px 25px;
        height: calc(100% - 8px);
        box-sizing: border-box;
        float:right;
        position relative
        .rank{
          background-image:url('~@/assets/img/toupiao/rank.png')
          background-size auto 100%
          background-repeat no-repeat
          width:100%;
          height:19px;
          line-height:19px;
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 24px;
          color: #AB0C15;
          text-indent:42px;
        }
      }
      .user-name{
        padding-top:20px;
        font-weight: bold;
        font-size: 28px;
        color: #0E0E0E;
        line-height: 33px;
      }
      .bott{
        width:calc(100% - 40px);
        height: 46px;
        line-height:46px;
        display:flex;
        position absolute
        bottom:0;
        justify-content: flex-end;
        .btn{
          width: 134px;
          height: 46px;
          line-height:46px;
          text-align:center;
          background: linear-gradient(0deg, #FE411B 0%, #FE9B1A 100%);
          box-shadow: 0px 5px 6px 0px rgba(254,79,27,0.15);
          border-radius: 22px 9px 33px 22px;
          font-weight: 500;
          font-size: 22px;
          color: #FFFFFF;
        }
        .toupiaonum{
          font-weight: 500;
          font-size: 22px;
          color: #AB0C15;
          padding-right:13px;
        }
      }
    }
  }
  .videos{
    width:100%;
    height:auto;
  }
  .playbtn{
    position: absolute;
    top: 72px;
    left: 103px;
    width:50px;
    height:50px;
    background-image:url('~@/assets/img/toupiao/play.png')
    background-size auto 100%
    background-repeat no-repeat
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border-radius: 50%;
  }
</style>
